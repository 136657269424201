export const UserMenu = () => {
  return (
    <nav className="snn-user-menu-wrapper">
      <div>
        <span className="snn-user-menu-icon material-symbols-outlined">home</span>
        <p className="snn-user-menu-text">Home</p>
      </div>
      <div>
        <span className="material-symbols-outlined">library_books</span>
        <p className="snn-user-menu-text">Abstracts</p>
      </div>
      <div className="hide">
        <span className="snn-user-menu-icon material-symbols-outlined">add</span>
        <p className="snn-user-menu-text">Add abstract</p>
      </div>
      <div>
        <span className="material-symbols-outlined">desk</span>
        <p className="snn-user-menu-text">Workshops</p>
      </div>
      <div>
        <span className="snn-user-menu-icon material-symbols-outlined">event_note</span>
        <p className="snn-user-menu-text">Program</p>
      </div>
    </nav>
  );
};
