import './About.css';
import IMG_0776 from '../../../assets/history_photos/IMG_0776.JPG';
import IMG_0576 from '../../../assets/history_photos/IMG_0576.JPG';
import IMG_0583 from '../../../assets/history_photos/IMG_0583.JPG';
import IMG_0578 from '../../../assets/history_photos/IMG_0578.JPG';
import IMG_0581 from '../../../assets/history_photos/IMG_0581.JPG';

export const About = () => {
  return (
    <>
      <section className="snn-page-section">
        <div className="snn-about">
          <section id="snn-committee">
            <h2 className="snn-title">Organizing Committee</h2>
            <div className="snn-committee-list-container">
              <ul>
                <li>Sergio Fuentes Moyado, chair</li>
                <li>Elena Smolentseva</li>
                <li>Noemí Abundiz Cisneros</li>
                <li>Catalina López Bastidas</li>
                <li>Mario H. Farias Sanchez</li>
                <li>Ruben D. Cadena Nava</li>
                <li>Ernesto Cota</li>
                <li>Leonardo Morales</li>
                <li>Perla Jazmín Sánchez López</li>
                <li>Karina Portillo</li>
                <li>Uriel Caudillo Flores</li>
                <li>Raúl Rangel Rojo</li>
                <li>Estrella Terán Hinojosa</li>
                <li>Carlos Belman Rodriguez</li>
                <li>Carolina Bohorquez Martinez</li>
                <li>Hugo Alejandro Borbon Nuñez</li>
                <li>H’Linh Hmok</li>
                <li>Javier Alonso López Medina</li>
              </ul>
            </div>
          </section>
          <section id="history">
            <h2 className="snn-title">History</h2>
            <p className="snn-justify">
              Since 1995 the Center of Nanoscience and Nanotechnology (CNyN), UNAM, Campus Ensenada,
              has carried out uninterruptedly this event whose main goal is presenting the latest
              results on Materials Science from research performed in prestigious institutions of
              Mexico and abroad. The first sixteen editions, the Symposium was named “Simposio en
              Ciencia de Materiales”; years later, between 2012 and 2014, the Symposium name was
              changed according to its new international scope and a change name of our institution:
              Centro de Nanociencias y Nanotecnologia (CNyN). In these years this event was named
              International Symposium on Nanoscience and Nanomaterials. In 2015 the event went back
              to a national scope and its name switched to Simposio de Nanociencias y
              Nanomateriales. From 2018 the Symposium of Nanoscience and Nanomaterials (IV SNN) has
              been jointly organized by CNyN and CICESE (Centro de Investigación Científica y
              Estudios Superiores de Ensenada). To date, there has been 25 editions of this academic
              event presenting the opportunity to stimulate interactions, to exchange experiences
              and to strengthen or initiate new collaborations.
            </p>
            <div className="snn-photo-section">
              <div>
                <img src={IMG_0776} alt="SNN 2019" />
              </div>
              <div>
                <img src={IMG_0576} alt="SNN 2019" />
              </div>
              <div>
                <img src={IMG_0583} alt="SNN 2019" />
              </div>
              <div>
                <img src={IMG_0578} alt="SNN 2019" />
              </div>
              <div>
                <img src={IMG_0581} alt="SNN 2019" />
              </div>
            </div>
          </section>
        </div>
      </section>

      <section className="snn-page-section">
        <h2 className="snn-title">Scientific Committee</h2>
        <div className="snn-scientific-committee">
          <section>
            <ul>
              <li>Ana Cecilia Noguez Garrido, IF-UNAM</li>
              <li>Gabriela Alicia Díaz Guerrero, IF-UNAM</li>
              <li>Sandra Elizabeth Rodil Posada, IIM-UNAM</li>
              <li>Rodolfo Zanella Specia, ICAT-UNAM</li>
              <li>Eugenio Méndez Méndez, CICESE</li>
              <li>Raúl Rangel Rojo, CICESE</li>
              <li>Patricia Juárez Camacho, CICESE</li>
            </ul>
          </section>
          <ul>
            <li>Priscilla Elizabeth Iglesias Vázquez, FC UABC</li>
            <li>Miguel Enrique Martinez Rosas, FC UABC</li>
            <li>Juan Cruz Reyes, FQ UABC</li>
            <li>Mercedes Teresita Oropeza Guzmán, ITT</li>
            <li>Norma Adriana Valdez, IIB-UNAM</li>
            <li>Mario Farías Sánchez, CNyN-UNAM</li>
            <li>Ernesto Cota Araiza, CNyN-UNAM</li>
          </ul>
          <section>
            <ul>
              <li>Rubén Darío Cadena Nava, CNyN-UNAM</li>
              <li>Sergio Fuentes Moyado, CNyN-UNAM</li>
              <li>Elena Smolentseva, CNyN-UNAM</li>
              <li>Leonardo Morales de la Garza, CNyN-UNAM</li>
              <li>Catalina López Bastida, CNyN-UNAM</li>
              <li>Uriel Caudillo Flores, CNyN-UNAM</li>
              <li>Noemi Abundiz Cisneros, CNyN-UNAM</li>
            </ul>
          </section>
        </div>
      </section>
    </>
  );
};
