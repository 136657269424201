import './Fees.css';

export const Fees = () => {
  return (
    <>
      <section className="snn-page-section">
        <h1 className="snn-title">Registration Fees</h1>

        <table className="snn-table-fees">
          <thead>
            <tr>
              <th></th>
              <th>Student</th>
              <th>
                Researcher/
                <br />
                Postdoc
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Early Bird, March 15 to April 15, 2024</td>
              <td className="snn-prices-cell">$700.00</td>
              <td className="snn-prices-cell">$1,400.00</td>
            </tr>
            <tr>
              <td>After April 15th, 2024</td>
              <td className="snn-prices-cell">$1,000.00</td>
              <td className="snn-prices-cell">$2,000.00</td>
            </tr>
          </tbody>
        </table>
        <p>Note: Registration Fees in Mexican pesos</p>
      </section>
    </>
  );
};
