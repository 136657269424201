import { TopBar } from '../../top-bar/TopBar';
import { PublicOptions } from '../../../data/publicOptions';
import { HotelCoral } from './HotelCoral';
import { Footer } from '../../footer/Footer';

export const HotelWrapper = () => {
  return (
    <>
      <TopBar options={PublicOptions} />
      <div className="content">
        <section className="snn-page-section">
          <h1 className="snn-section-title">Hotels</h1>
        </section>
        <HotelCoral />
      </div>
      <Footer />
    </>
  );
};
