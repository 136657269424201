import { TopBar } from '../../top-bar/TopBar';
import { Footer } from '../../footer/Footer';
import { PublicOptions } from '../../../data/publicOptions';
import { About } from '../about/About';
import { Banner } from './Banner';
import { Topics } from './Topics';
import './Home.css';
import { memo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const HomeWrapper = memo(() => {
  const { hash } = useLocation();
  const navigate = useNavigate();

  const access_token = localStorage.getItem('access_token');

  useEffect(() => {
    if (hash) {
      console.log('Getting token');
      console.log(hash.substring(1));
      const pairs = hash.substring(1).split('&');
      const result = {};

      pairs.forEach((pair) => {
        const [key, value] = pair.split('=');
        result[key] = decodeURIComponent(value);
      });

      localStorage.setItem('id_token', result['id_token']);
      localStorage.setItem('access_token', result['access_token']);
      localStorage.setItem('expires_in', result['expires_in']);
      localStorage.setItem('token_type', result['token_type']);

      navigate('/home');
    }
  }, []);

  useEffect(() => {
    if (access_token) {
      navigate('/home');
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://connect.facebook.net/es_LA/sdk.js#xfbml=1&version=v5.0';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <TopBar options={PublicOptions} />
      <div className="content">
        <Banner />
        <Topics />
        <About />
        <section className="snn-page-section">
          <h2 className="snn-title">Social media</h2>
          <div className="snn-social-wrapper">
            <div
              className="fb-page"
              data-href="https://www.facebook.com/simposiocnyn"
              data-tabs="timeline"
              data-width="1500"
              data-height="500"
              data-small-header="true"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="true"
            >
              <blockquote
                cite="https://www.facebook.com/simposiocnyn"
                className="fb-xfbml-parse-ignore"
              >
                <a href="https://www.facebook.com/simposiocnyn">
                  Simposio de Nanociencias y Nanomateriales
                </a>
              </blockquote>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
});
HomeWrapper.displayName = 'HomeWrapper';
export default HomeWrapper;
