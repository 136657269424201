import { TopBar } from '../../top-bar/TopBar';
import { PublicOptions } from '../../../data/publicOptions';
import { Footer } from '../../footer/Footer';
import { SpeakersList } from '../../../data/speakersList';
import { Speaker } from './Speaker';
import { KeynoteList } from '../../../data/KeynotesList';

export const SpeakersWrapper = () => {
  return (
    <>
      <TopBar options={PublicOptions} />
      <div className="content">
        <section className="snn-page-section">
          <h2 className="snn-title">Plenaries Speakers</h2>
          <div className="snn-speakers-cards-wrapper">
            {SpeakersList.map((speaker) => (
              <Speaker key={speaker.id} {...speaker} />
            ))}
          </div>
          <h2 className="snn-title">Keynotes Speakers</h2>
          <div className="snn-keynotes-cards-wrapper">
            {KeynoteList.map((speaker) => (
              <Speaker key={speaker.id} {...speaker} />
            ))}
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};
