import AldoRomer from '../assets/images/speakers/aldo_romero.jpeg';
import ArturErbe from '../assets/images/speakers/artur_erbe.png';
import PierreBrevet from '../assets/images/speakers/pierre-francois brevet.png';
import WilliamGelbart from '../assets/images/speakers/william-gelbart.png';

export const SpeakersList = [
  {
    id: 0,
    name: 'Dr. Aldo Romero',
    affiliation: 'West Virginia University, USA',
    img: AldoRomer,
  },
  {
    id: 1,
    name: 'Prof. Pierre-François Brevet',
    affiliation: 'Institut Lumière Matière, Université Claude Bernard Lyon 1, France',
    img: PierreBrevet,
  },
  {
    id: 2,
    name: 'Prof. Artur Erbe',
    affiliation: 'Helmholtz-Zentrum Dresden-Rossendorf, Germany',
    img: ArturErbe,
  },
  {
    id: 3,
    name: 'Prof. William M. Gelbart',
    affiliation: 'UCLA, USA',
    img: WilliamGelbart,
  },
];
