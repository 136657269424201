import PropTypes from 'prop-types';
import './speakers.css';

export const Speaker = (speaker) => {
  return (
    <div className="snn-speaker-card">
      <div className="snn-speakers-card-img">
        <img src={speaker.img} alt={speaker.name} />
      </div>
      <div className="snn-speakers-card-description">
        <h2>{speaker.name}</h2>
        <p>{speaker.affiliation}</p>
      </div>
    </div>
  );
};

Speaker.propTypes = {
  option: PropTypes.any,
};
