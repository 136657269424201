import OliviaGraeve from '../assets/images/keynotes/olivia-graeve.jpeg';
import Hailin from '../assets/images/keynotes/Hailin.png';

export const KeynoteList = [
  {
    id: 0,
    name: 'Dr. Olivia Graeve',
    affiliation: 'UC San Diego Jacobs School of Engineering',
    img: OliviaGraeve,
  },
  {
    id: 1,
    name: 'Dr. Hailin Zhao Hu',
    affiliation: 'Instituto de Energías Renovables, UNAM',
    img: Hailin,
  },
];
