import { SideMenu } from '../user-home/SideMenu';
import { useIsValidToken } from '../../hooks/TokenHook';
import { useEffect, useState } from 'react';
import { Logout } from '../../../App';
import { useNavigate, useParams } from 'react-router-dom';
import { TopicsList } from '../../../data/TopicsList';
import './ViewAbstract.css';
import parse from 'html-react-parser';

export const fetchAbstractById = async (abstractId, setAbstract) => {
  try {
    const response = await fetch(
      'https://nt6dwl1xpc.execute-api.us-west-1.amazonaws.com/produccion/abstract/' + abstractId,
      {
        headers: {
          'user-token': localStorage.getItem('id_token'),
        },
      },
    );
    const data = await response.json();
    setAbstract(data.Items[0]);
    console.log(data.Items[0]);
  } catch (error) {
    console.error('Error:', error);
  }
};

export const ViewAbstract = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [abstract, setAbstract] = useState();
  const isValidToken = useIsValidToken();

  const [affiliationsString, setAffiliationString] = useState('');
  const [authorsString, setAuthorString] = useState('');
  const [referencesString, setReferencesString] = useState('');

  function createMarkup(html) {
    return {
      __html: html,
    };
  }

  const getReferencesString = (references) => {
    const referencesArray = references; //references.split(';');
    let temp = '';
    for (const aff in referencesArray) {
      if (aff > 0) {
        temp += '<br/>';
      }
      temp += `[${parseInt(aff) + 1}] ${referencesArray[aff]}`;
    }
    return `${temp}`;
  };

  useEffect(() => {
    let tempAuthorString = '';
    let tempAffiliationString = '';

    if (abstract === undefined) {
      return;
    }

    let affiliations = Array.from(
      new Set(abstract.authors.map((author) => author.affiliations).flat()),
    );

    for (const iAffiliation in affiliations) {
      if (tempAffiliationString.length > 0) {
        tempAffiliationString += '<br/> ';
      }
      tempAffiliationString += `<sup>${parseInt(iAffiliation) + 1}</sup> ${
        affiliations[iAffiliation]
      }`;
    }

    for (const author of abstract.authors) {
      // Add ", " to tempAuthorString if is not the first author
      if (tempAuthorString.length > 0) {
        tempAuthorString += ', ';
      }
      if (author.name === undefined) {
        continue;
      }
      tempAuthorString += `${author.lastName} ${
        author.name.length > 0 ? author.name[0] + '.' : ''
      }`;
      // Add superscript to author
      if (author.affiliations) {
        tempAuthorString += `<sup>${author.affiliations
          .map((affiliation) => {
            return affiliations.indexOf(affiliation) + 1;
          })
          .join(', ')}</sup>`;
      }
    }
    setAuthorString('<p>' + tempAuthorString + '</p>');
    setAffiliationString(tempAffiliationString);
  }, [abstract]);

  useEffect(() => {
    if (abstract === undefined) {
      return;
    }
    setReferencesString(getReferencesString(abstract.references));
  }, [abstract]);

  useEffect(() => {
    if (isValidToken) {
      fetchAbstractById(id, setAbstract);
    } else {
      Logout();
      navigate('/');
    }
  }, []);
  return (
    <div className="snn-user-home-main-wrapper">
      <SideMenu />
      {abstract ? (
        <div className="snn-abstract-submission-main-grid-wrapper">
          <div
            className={
              'snn-expand-grid-4 snn-view-abstract-header ' +
              {
                'Advanced materials and physics of nanostructures':
                  ' snn-user-home-abstract-advanced',
                Bionanotechnology: ' snn-user-home-abstract-bio',
                Nanophotonics: ' snn-user-home-nanophotonic',
                'Physical-chemistry of nanomaterials and nanocatalysis': ' snn-user-home-catalysis',
                'Tech innovation in nanoscience': ' snn-user-home-innovation',
              }[abstract.category]
            }
          >
            <img src={TopicsList.filter((t) => t.name === abstract.category)[0].src_shield} />
            <div className="snn-view-abstract-header-title-wrapper">
              <h1 className="snn-view-abstract-header-title">{abstract ? abstract.title : null}</h1>
              <div className="snn-view-abstract-header-info-wrapper">
                <p>
                  <strong>Status:</strong> {abstract.status}
                </p>
                <p>
                  <strong>Category:</strong> {abstract.category}
                </p>
                <p>
                  <strong>Presentation type:</strong> {abstract.presentation_type}
                </p>
              </div>
            </div>
          </div>

          <div className="snn-expand-grid-4">
            <div className="snn-abstract-page snn-abstract-view-page-wrapper">
              <h1 className="snn-abstract-page-title">{abstract.title}</h1>
              {authorsString.length > 20 ? (
                <>
                  <p className="snn-abstract-page-authors">{parse(authorsString)}</p>
                </>
              ) : (
                <></>
              )}
              {affiliationsString.length > 13 ? (
                <>
                  <p className="snn-abstract-page-affiliation">{parse(affiliationsString)}</p>
                </>
              ) : (
                <></>
              )}
              {abstract.background.length > 8 ? (
                <>
                  <h2 className="snn-abstract-page-sub-title">Introduction</h2>
                  <div
                    className="snn-abstract-page-text"
                    dangerouslySetInnerHTML={createMarkup(abstract.background)}
                  ></div>
                </>
              ) : (
                <></>
              )}
              {abstract.methodology.length > 8 ? (
                <>
                  <h2 className="snn-abstract-page-sub-title">Methodology</h2>
                  <div
                    className="snn-abstract-page-text"
                    dangerouslySetInnerHTML={createMarkup(abstract.methodology)}
                  ></div>
                </>
              ) : (
                <></>
              )}
              {abstract.results.length > 8 ? (
                <>
                  <h2 className="snn-abstract-page-sub-title">Results</h2>
                  <div
                    className="snn-abstract-page-text"
                    dangerouslySetInnerHTML={createMarkup(abstract.results)}
                  ></div>
                </>
              ) : (
                <></>
              )}
              {abstract.img_path && (
                <img
                  width={abstract.image_width}
                  height={abstract.image_height}
                  className="snn-abstract-img"
                  src={
                    abstract.img_path.substring(0, 60) +
                    encodeURIComponent(abstract.img_path.substring(60))
                  }
                  alt="Abstract"
                />
              )}
              {abstract.img_path ? (
                <>
                  <p className="snn-abstract-page-image-footer">{abstract.image_footer}</p>
                </>
              ) : (
                <></>
              )}
              {abstract.conclusion.length > 8 ? (
                <>
                  <h2 className="snn-abstract-page-sub-title">Conclusion</h2>
                  <div
                    className="snn-abstract-page-text"
                    dangerouslySetInnerHTML={createMarkup(abstract.conclusion)}
                  ></div>
                </>
              ) : (
                <></>
              )}
              {referencesString.length > 4 ? (
                <>
                  <h2 className="snn-abstract-page-sub-title">References</h2>
                  <p className="snn-abstract-page-text">{parse(referencesString)}</p>
                </>
              ) : (
                <></>
              )}
              {abstract.acknowledgments ? (
                <>
                  <h2 className="snn-abstract-page-sub-title">Acknowledgments</h2>
                  <p className="snn-abstract-page-text">{abstract.acknowledgments}</p>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
