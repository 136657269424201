// import { AbstractSubmission } from './AbstractSubmission';
import { SideMenu } from '../user-home/SideMenu';

export const AbstractSubmissionWrapper = () => {
  return (
    <div className="snn-user-home-main-wrapper">
      <SideMenu />
      {/*<AbstractSubmission />*/}
      <div className="snn-panel-form-wrapper snn-abstract-submission-main-grid-wrapper">
        <h1 className="snn-user-home-main-content-header snn-expand-grid-4">
          We are making updates, come back later.
        </h1>
      </div>
    </div>
  );
};
