import PropTypes from 'prop-types';

export const TopUserMenu = ({ name }) => {
  return (
    <nav className="snn-top-user-menu-wrapper">
      <div className="hide-on-desktop">
        <span className="snn-user-menu-icon material-symbols-outlined">menu</span>
      </div>
      <div className="hide-on-mobile snn-expand-grid-2">
        <h1 className="snn-user-home-main-content-header">Welcome {name}</h1>
      </div>
      <div className="hide-on-desktop"></div>
      <div></div>
      <div></div>
      <div>
        {/*<span className="snn-user-menu-icon material-symbols-outlined">notifications</span>*/}
      </div>
    </nav>
  );
};

TopUserMenu.propTypes = {
  name: PropTypes.string,
};
