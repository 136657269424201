export const PublicOptions = [
  {
    label: 'Home',
    link: '/',
  },
  {
    label: 'Speakers',
    link: '/speakers',
  },
  {
    label: 'Fees',
    link: '/fees',
  },
  {
    label: 'Hotel Accommodation',
    link: '/hotels',
  },
  {
    label: 'Proceedings',
    link: '/proceedings',
  },
  {
    label: 'About',
    link: '/about',
  },
];
