import './RegisteredAbstract.css';
import { TopicsList } from '../../../data/TopicsList';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useIsValidToken } from '../../hooks/TokenHook';
import { Logout } from '../../../App';
import { fetchUserAbstractData } from '../user-home/UserHome';
import { LoadingLayer } from '../../LoadingLayer';

export const saveAbstractRequest = async (body) => {
  try {
    const response = await fetch(
      'https://nt6dwl1xpc.execute-api.us-west-1.amazonaws.com/produccion/abstract/' +
        body.abstract_id,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'user-token': localStorage.getItem('id_token'),
        },
        body: JSON.stringify(body),
      },
    );
    const data = await response.json();
    console.log(data);
  } catch (error) {
    console.error('Error:', error);
  }
};

export const RegisteredAbstracts = () => {
  const navigate = useNavigate();
  const [abstracts, setAbstracts] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  const isValidToken = useIsValidToken();

  const deleteAbstract = (abstractId, updates_at) => {
    console.log('Deleting');
    setIsLoading(true);
    setLoadingMessage('Deleting abstracts');
    saveAbstractRequest({ abstract_id: abstractId, updated_at: updates_at }).then(() =>
      window.location.reload(),
    );
  };

  useEffect(() => {
    if (isValidToken) {
      setIsLoading(true);
      setLoadingMessage('Loading');
      fetchUserAbstractData(setAbstracts).then(() => setIsLoading(false));
    } else {
      Logout();
      navigate('/');
    }
  }, []);

  return (
    <div className="snn-abstract-submission-main-grid-wrapper">
      <LoadingLayer isLoading={isLoading} action={loadingMessage} />
      <div className="snn-expand-grid-4">
        <h1 className="snn-user-home-main-content-header">Registered abstracts</h1>
        <div>
          {abstracts
            ? abstracts.map((abstract) => (
                <Link key={abstract.title} to={`/abstracts/${abstract.id}`}>
                  <div
                    className={
                      'snn-abstract-registered-card' +
                      {
                        'Advanced materials and physics of nanostructures':
                          ' snn-user-home-abstract-advanced',
                        Bionanotechnology: ' snn-user-home-abstract-bio',
                        Nanophotonics: ' snn-user-home-nanophotonic',
                        'Physical-chemistry of nanomaterials and nanocatalysis':
                          ' snn-user-home-catalysis',
                        'Tech innovation in nanoscience': ' snn-user-home-innovation',
                      }[abstract.category] +
                      (abstract.status === 'Submitted'
                        ? ' snn-abstract-registered-card-submitted'
                        : ' snn-abstract-registered-card-draft')
                    }
                  >
                    <p className="snn-user-home-main-content-small-text">{abstract.status}</p>
                    <div className="snn-abstract-registered-card-text">
                      <p className="snn-user-home-main-content-large-semi">{abstract.title}</p>
                      <img src={TopicsList.filter((t) => t.name === abstract.category)[0].src_bg} />
                    </div>
                    <div className="snn-abstract-registered-card-buttons-wrapper">
                      <Link to="/abstracts/submit">
                        <div className="snn-abstract-registered-card-button">
                          Edit <span className="material-symbols-outlined">edit</span>
                        </div>
                      </Link>
                      <div
                        onClick={(event) => {
                          event.preventDefault();
                          deleteAbstract(abstract.id, abstract.updated_at);
                        }}
                        className="snn-abstract-registered-card-button snn-abstract-registered-card-button-delete"
                      >
                        Delete <span className="material-symbols-outlined">close</span>
                      </div>
                    </div>
                  </div>
                </Link>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};
