import { ReactComponent as BannerIMG } from '../../../assets/2024/SVG/Banner.svg';

export const Banner = () => {
  return (
    <>
      <section className="page-section snn-banner">
        <div>
          <BannerIMG />
        </div>
      </section>
    </>
  );
};
