import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AbstractSubmissionWrapper } from './components/screens/abstracts-submission/AbstractSubmissionWrapper';
import HomeWrapper from './components/screens/home/HomeWrapper';
import { AboutWrapper } from './components/screens/about/AboutWrapper';
import { FeesWrapper } from './components/screens/fees/FeesWrapper';
import { ProceedingsWrapper } from './components/screens/proceedings/ProceedingsWrapper';
import { TopicWrapper } from './components/screens/topics/TopicWrapper';
import { HotelWrapper } from './components/screens/hotels/HotelWrapper';
import { SpeakersWrapper } from './components/screens/speakers/SpeakersWrapper';
import { UserHome } from './components/screens/user-home/UserHome';
import { RegisteredAbstractWrapper } from './components/screens/registered-abstracts/RegisteredAbstractWrapper';
import { Profile } from './components/screens/profile/Profile';
import { ViewAbstract } from './components/screens/abstract/ViewAbstract';

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomeWrapper />,
  },
  {
    path: '/home',
    element: <UserHome />,
  },
  {
    path: '/profile',
    element: <Profile />,
  },
  {
    path: '/about',
    element: <AboutWrapper />,
  },
  {
    path: '/speakers',
    element: <SpeakersWrapper />,
  },
  {
    path: '/hotels',
    element: <HotelWrapper />,
  },
  {
    path: '/topics/:id',
    element: <TopicWrapper />,
  },
  {
    path: '/fees',
    element: <FeesWrapper />,
  },
  {
    path: '/proceedings',
    element: <ProceedingsWrapper />,
  },
  {
    path: '/abstracts',
    element: <RegisteredAbstractWrapper />,
  },
  {
    path: '/abstracts/submit',
    element: <AbstractSubmissionWrapper />,
  },
  {
    path: '/abstracts/:id',
    element: <ViewAbstract />,
  },
]);

export const Logout = () => {
  localStorage.removeItem('id_token');
  localStorage.removeItem('access_token');
  localStorage.removeItem('expires_in');
  localStorage.removeItem('token_type');
};
function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
